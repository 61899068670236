import React, { lazy, Suspense, useState } from "react";
import { Lia } from "./liaServerConnector";
import '../common/defaultStyles/default.styl';
import './lia-chat.styl';
const FzChatReactState = lazy(() =>
  import("./liaReactState").then((module) => ({
    default: module.FzChatReactState,
  }))
);

const BubbleButton = ({ setMinimized, icon = "fa-commenting" }) => {
  return (
    <button
      className="def-sf-highlighted def-mr-normal def-mb-normal lia-btn"
      aria-label="Abrir o chat para obter ajuda."
      onClick={
        setMinimized
          ? () => {
              setMinimized(false);
            }
          : undefined
      }
    >
      <i className={`fa ${icon}`} style={{ fontSize: "35px" }} />
    </button>
  );
};

class FZChat {
  constructor(channel, storageTranslation) {
    this.serverHandler = new Lia(channel, storageTranslation);
    if (window) {
      window.lia = this.serverHandler; //TODO: remove this after testing. won't work on mobile.
    }
    this.setCurrUserContext = (contextObj) => {
      lia.setCurrUserContext(contextObj);
    };
  }
  ReactComponent = (props) => {
    const [minimized, setMinimized] = useState(true);
    const [showMode, setShowMode] = useState(props.showMode);
    const [messagesList, setMessagesList] = useState([]);

    return (minimized && showMode == "FLOATING") ||
      (showMode == "FULL_SCREEN" && minimized) ? (
      <BubbleButton setMinimized={setMinimized} />
    ) : (
      <Suspense fallback={<BubbleButton icon="fa-spinner fa-spin" />}>
        <FzChatReactState
          serverHandler={this.serverHandler}
          showMode={showMode}
          setShowMode={setShowMode}
          isMobile={props.isMobile}
          minimized={minimized}
          setMinimized={setMinimized}
          messagesList={messagesList}
          setMessagesList={setMessagesList}
        />
      </Suspense>
    );
  };
}

export { FZChat };
