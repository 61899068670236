import { cloneDeep } from "lodash";
import api from "../api";
import Cookie from 'js-cookie'

function allQueryStrings() {
  var qs = window.location.search;
  var res = {};
  
  if (qs.length === 0 || qs.substring(1).length === 0) {
      return res;
  }
  var split = qs.substring(1).split('&');
  if (split.length === 0) {
      return res;
  }
  for (var i = 0; i < split.length; i++) {
      var param = split[i];
      var paramSplit = param.split('=');
      if (paramSplit.length !== 2 || paramSplit[0] == "token") {
          continue;
      }

      var key = paramSplit[0];
      var val = paramSplit[1];

      res[key] = val;
  }

  // garantindo que todas as querystrings sejam gravadas, porém num jsonString
  const prevQs = JSON.parse(getCookie("qsCookie") || "{}")
  setCookie("qsCookie", JSON.stringify({...prevQs, ...res}), 1)
  return res;
}

const saveUTMs = () => {
  const queries = allQueryStrings();
  const utmKeysFromUrl = Object.keys(queries);
  const sortedUTMSfromURL = utmKeysFromUrl.sort((keyA, keyB) => keyB.localeCompare(keyA));
  const pieces = getQueryStringsToSave()
  const filteredUTMs = sortedUTMSfromURL.filter(key => getRegExpFromPieces(pieces).test(key));
  const updatedUTMs = filteredUTMs.reduce((accumulator, queryKey) => ({ ...accumulator, [queryKey.replace(/^fzm_/, "utm_")]: queries[queryKey] }), {});
  Object.keys(updatedUTMs).forEach((utm) => {
    setCookie(utm, updatedUTMs[utm], 1);
  });
}

const getQueryStringsToSave = () => {
  const affiliateVariables = getCookie("affiliateVariables") || "clientId"
  const affiliateVariablesPieces = affiliateVariables.split(",")
  const pieces = ["fzm_", "utm_", ...affiliateVariablesPieces]
  return pieces
}
const getRegExpFromPieces = (pieces = []) => {
  if (!Array.isArray(pieces)) {
    pieces = [pieces]
  }
  return new RegExp(`^(${pieces.join("|")})`,"g")
}

const getUtms = () => {
  const cookieNames = document.cookie.split(';').map(cookie => cookie.replace(/=.*/, "").replace(/^ +/, ""));
  const qsCookie = JSON.parse(getCookie("qsCookie") || "{}")
  const affiliateVariables = getCookie("affiliateVariables") || "clientId"
  const affiliateVariablesPieces = affiliateVariables.split(",")
  const pieces = getQueryStringsToSave()
  const utmsToPersist = cookieNames.filter(name => getRegExpFromPieces(pieces).test(name));
  const utms = utmsToPersist.reduce((prev, curr) => (getCookie(curr) ? {...prev, [curr]:getCookie(curr)} : prev), {})
  const affiliateIds = {
    ...affiliateVariablesPieces.reduce((prev, curr) => {
      if (!qsCookie[curr]) {
        return prev
      }
      return {
        ...prev,
        affiliateIdField: qsCookie[curr]
      }
    }, {"affiliateIdField": "not-found"})
  }
  return {...utms, ...affiliateIds}
}

const saveUTMsWithAffiliateIds = () => {
  if (!getCookie("affiliateVariables")) {
    try {
      (async () => {
        const res = await api.Get("/parameters/affiliate.webhook.urlvariables/all")
        setCookie("affiliateVariables", res.join(","), 1)
      })()
    } catch (err) {
      console.error("Error trying to get affiliateIdKeys", err)
    }
  }
  saveUTMs();
}

const convertUtmsToQueryString = (utms) => {
  let utmsArr = [];

  for (const prop in utms) {
    utmsArr.push(`${prop}=${utms[prop]}`)
  }

  return utmsArr.join('&')
}

function getSearchFromLocation(location, q) {
    const urlParams = new URLSearchParams(location.search);
    return urlParams.get(q) || undefined;
}

function getCookie(name) {
  if(!name) return Cookie.get() ?? null;
  
  return Cookie.get(name) ?? null;
}

function setCookie(name, val, expires) {
  Cookie.set(name, val, { expires })
}

function removeCookie(name) {
  Cookie.remove(name)
}

function isInProd(locationHostname) {
  const nonProd = ["local", "beta"];
  const filteredHosts = nonProd.filter((it) => locationHostname.includes(it) || /\d/.test(locationHostname));
  return filteredHosts.length == 0;
}

function exitFullscreen() {
  if (document.exitFullscreen) {
      document.exitFullscreen();
  } else if (document.mozCancelFullScreen) {
      document.mozCancelFullScreen();
  } else if (document.webkitExitFullscreen) {
      document.webkitExitFullscreen();
  } else if (document.msExitFullscreen) {
      document.msExitFullscreen();
  }
}
function enterFullscreen(element) {
  if (element.requestFullscreen) {
      element.requestFullscreen();
  } else if (element.mozRequestFullScreen) {
      element.mozRequestFullScreen();
  } else if (element.webkitRequestFullscreen) {
      element.webkitRequestFullscreen(Element.ALLOW_KEYBOARD_INPUT);
  } else if (element.msRequestFullscreen) {
      element.msRequestFullscreen();
  }
}

function getHostname() {
  let hostname;
  if (!isInProd(window.location.hostname)) {
    hostname = window.location.origin;
  } else if (window.location.href.indexOf("finanzero") > -1) {
    hostname = `https://${window.location.host}`;
  } else {
    hostname = "https://finanzero.com.br";
  }
  return hostname;
}

// high entropy
const highEntropyValuesPrefix = "bhe:"

function concatenateBHEList(arrObjs = [{brand: "N/A", version: "0"}]) {
  return arrObjs.reduce((prev, curr) => {
    const res = curr.brand + ":" + curr.version
    return prev != "" ? prev + "|" + res : res
  }, "")
}

async function getBrowserHighEntropyInfo(hints) {
  if (!navigator.userAgentData) {
    return Promise.resolve();
  }

  // Verify that this is a Chromium-based browser
  let is_chromium = false;
  let chromium_version;
  const is_chrome_ua_pattern = new RegExp(
    "AppleWebKit/537.36 \\(KHTML, like Gecko\\) Chrome/\\d+.\\d+.\\d+.\\d+ (Mobile )?Safari/537.36$"
  );
  navigator.userAgentData.brands.forEach((value) => {
    if (value.brand == "Chromium") {
      // Let's double check the UA string as well, so we don't accidentally
      // capture a headless browser or friendly bot (which should report as
      // HeadlessChrome or something entirely different).
      is_chromium = is_chrome_ua_pattern.test(navigator.userAgent);
      chromium_version = value.version;
    }
  });
  if (!is_chromium || chromium_version < 100) {
    // If this is not a Chromium-based browser, the UA string should be very
    // different. Or, if this is a Chromium lower than 100, it doesn't have
    // all the hints we rely on. So let's bail.
    return Promise.resolve();
  }

  return new Promise((resolve) => {
    navigator.userAgentData.getHighEntropyValues(hints).then((values) => {
      let initialValues = {
        platform: navigator.userAgentData?.platform,
        version: chromium_version,
      };
      values = Object.assign(initialValues, values);
      resolve(values);
    })
  })
}

const getHighEntropyBrowser = async () =>{
  const defaultValue = {
    [highEntropyValuesPrefix + "mobile"]: "false",
    [highEntropyValuesPrefix + "platform"]: "unknown",
    [highEntropyValuesPrefix + "brand"]: "unknown",
    [highEntropyValuesPrefix + "model"]: "unknown",
  }
  try {
    const paramsList = [
      "architecture",
      "uaFullVersion",
      "model",
      "mobile",
      "platformVersion",
      "fullVersionList"
    ]
    const highEntropyValues = await navigator?.userAgentData?.getHighEntropyValues(paramsList) // getBrowserHighEntropyInfo(paramsList) // navigator?.userAgentData?.getHighEntropyValues(paramsList)
    if (!highEntropyValues) {
      return defaultValue
    }
    return {
      ...Object.keys(highEntropyValues).reduce((prev, curr) => {
        const newVal = Array.isArray(highEntropyValues[curr]) ? concatenateBHEList(highEntropyValues[curr]) : String(highEntropyValues[curr]);
        return {
          ...prev,
          [highEntropyValuesPrefix + curr]: newVal
        }
      }, {
        [highEntropyValuesPrefix + "updated"]: new Date().toISOString()
      })
    }
  } catch (error) {
    // TODO - Avisar cliente sobre o dispositivo não compatível?
    console.warn("Error on getHighEntropyBrowser", error?.toString() || String(error) || "no error")
    return {
      ...defaultValue,
      [highEntropyValuesPrefix + "errorString"]: error?.toString() || String(error) || "no error"
    }
  }
}

const updateTrackingDataWithHighEntropyBrowser = async (app = {}) => {
  let ret = cloneDeep(app)
  var trackingData = app.trackingData
  if (
    !trackingData[highEntropyValuesPrefix + "platform"]
    || trackingData[highEntropyValuesPrefix + "platform"] === "unknown"
    || trackingData[highEntropyValuesPrefix + "mobile"] !== "true"
  ) {
    const deviceInfo = await getHighEntropyBrowser();
    if (deviceInfo[highEntropyValuesPrefix + "platform"] !== "unknown") {
      console.log("trackingData updated with new deviceInfo", trackingData, deviceInfo)
      ret.trackingdatahistoryitem = {
        type: "device",
        createdAt: new Date().toISOString(),
        utms: {
          ...trackingData,
          ...deviceInfo,
        }
      }
    }
  }
  return ret
}

export {
  getCookie,
  getHostname,
  setCookie,
  getQueryStringsToSave,
  getRegExpFromPieces,
  removeCookie,
  allQueryStrings,
  getUtms,
  convertUtmsToQueryString,
  getSearchFromLocation,
  saveUTMs,
  saveUTMsWithAffiliateIds,
  isInProd,
  exitFullscreen,
  enterFullscreen,
  getHighEntropyBrowser,
  updateTrackingDataWithHighEntropyBrowser,
};
