import React from "react";
import { FZChat } from "../../fzChat/fzChat";
import { localStoragePrefixes } from "../../common/api/auth";
import { createRoot } from "react-dom/client";

if (!window.finanzero) window.finanzero = {};

const storageTranslation = {
  prefix: localStoragePrefixes["LIA_LOCALSTORAGE_PREFIX"],
  set: (key, value) => {
    localStorage.setItem(key, value);
  },
  get: (key) => {
    return localStorage.getItem(key);
  },
  remove: (key) => {
    localStorage.removeItem(key);
  },
};
const FZChatInstance = new FZChat("parati", storageTranslation);

const fzChatReactComponent = (
  <FZChatInstance.ReactComponent showMode={"FLOATING"} />
);

window.finanzero.webchat = {};
window.finanzero.webchat.setCurrUserContext = FZChatInstance.setCurrUserContext;

const copaContext = {
  location: "CHAT_INIT",
  locDesc: "Preenchimento de formulário pré comparação de empréstimos",
  locPath: location.pathname,
  formType: window.finanzero.formType,
  logged: false,
};
window.finanzero.webchat.setCurrUserContext(copaContext);

const pageElementToMount = document.getElementById("root-lia_chat");
window.finanzero.renderLiaChat = () => {
  // DANGER: Do not modify the name of this function. Tag manager calls it to control when to show the chat.
  if (window.ReactNativeWebView) {
    return;
  }
  if (pageElementToMount && !!window.finanzero.LiaChat) {
    const render = () => {
      const root = createRoot(pageElementToMount);
      root.render(fzChatReactComponent);
    };
    if (
      "fetch" in window
    ) {
      render();
    } else {
      import("./polyfills").then(render);
    }
  }
};

if (
  pageElementToMount &&
  !!window.finanzero.LiaChat &&
  window.finanzero.renderLiaChat instanceof Function
) {
  window.finanzero.renderLiaChat();
}
